import { ImageZoom, StyledImageContainer } from '../../styles/tabor';
import { MessageDiv, StyledArticle } from '../../styles';
import React, { useEffect, useState } from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Layout } from '../../components/Layout';
import { StyledH2 } from '../../styles/common';
import { graphql } from 'gatsby';
import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
	title='Tabor historyczny'
	description='Tabor historyczny'
	/>);


const isBrowser = () => typeof window !== 'undefined';
const mod = (n, m) => {
	const remain = n % m;
	return Math.floor(remain >= 0 ? remain : remain + m);
};
const HistorycznyPage = ({ data }) => {
	const { allHistorycznyJson } = data;
	const { edges } = allHistorycznyJson;
	const [zoom, setZoom] = useState(false);
	const [image, setImage] = useState(null);
	const [isMobile, setIsMobile] = useState(false);
	const handleOnResize = () => {
		setIsMobile(isBrowser() ? window.innerWidth < 800 : false);
	};
	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('scroll', handleScrollEvent);
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('scroll', handleScrollEvent);
			}
		};
	});

	const handleScrollEvent = () => {
		setZoom(false);
	};

	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('resize', handleOnResize);
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('resize', handleOnResize);
			}
		};
	}, []);
	return (
		<>
			<Layout>
				<StyledArticle>
					<StyledH2 as="h1" color="info">
						Tabor historyczny
					</StyledH2>
					<MessageDiv>
						{edges.map(({ node }, index) => {
							const { id, opis, obrazek } = node;

							return (
								<div key={id}>
									<div className="p50">
										<StyledImageContainer
											onClick={() => {
												if (!isMobile) {
													setZoom(true);
													setImage(getImage(obrazek.childImageSharp.i1));
												}
											}}
										>
											<GatsbyImage
												image={getImage(obrazek.childImageSharp.i2)}
												alt=""
												className="bwfilter"
											/>
											<div className="name">{opis}</div>
										</StyledImageContainer>
									</div>
									<div
										className={
											mod(index, 2) || index === edges.length - 1 ? 'clear' : ''
										}
									/>
								</div>
							);
						})}
					</MessageDiv>
				</StyledArticle>
			</Layout>
			{zoom ? (
				<ImageZoom onClick={() => setZoom(false)}>
					<GatsbyImage objectFit="cover" image={image} alt="" />
				</ImageZoom>
			) : null}
		</>
	);
};

export const pageQuery = graphql`
	query {
		allHistorycznyJson {
			edges {
				node {
					id
					opis
					obrazek {
						childImageSharp {
							i1: gatsbyImageData(layout: FIXED, width: 640) 
							i2: gatsbyImageData(layout: FULL_WIDTH, quality:85)
						}
					}
				}
			}
		}
	}
`;

export default HistorycznyPage;
